import { Box, Grid } from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import OneThird from "../../grid/OneThird";
import Name from "../../Form/Name";
import Email from "../../Form/Email";
import Id from "../../Form/Id";
import Data from "../../Form/data";
import Full from "../../grid/full";
import Reason from "../../Form/Reason";
import Text from "../../General/text";
import Btn from "../../General/btn";
import { HelperContext } from "../../../Context/Helper";
import emailjs from "@emailjs/browser";

export default function Form() {
  const { theme, isLessThanMD, name, email } = useContext(HelperContext);
  const [processing, setProcessing] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!name || !email) return alert("Name and Email are Mandatory");

    setProcessing(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(() => {
        alert("SUCCESS! - Account Deletion Request Submitted");
        window.location.href = "/";
      })
      .catch((error) => {
        alert("FAILED... Could not Send Account Deletion Request", error.text);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Box
      component={"form"}
      ref={form}
      onSubmit={sendEmail}
      sx={{ mt: 5, mb: 10 }}
    >
      <Grid container spacing={1}>
        <OneThird content={<Name />} />
        <OneThird content={<Email />} />
        <OneThird content={<Id />} />
        <Full content={<Data />} />
        <Full content={<Reason />} />
        <Full
          content={
            <Text
              sx={{
                fontSize: 12,
                fontWeight: 400,
                my: 2,
                mb: 5,
                fontStyle: "italic",
              }}
              content={
                "*We will process your request within 30 days. You will receive a confirmation email once completed."
              }
            />
          }
        />
        <Full
          sx={{ textAlign: "center" }}
          content={
            <Btn
              handleClick={sendEmail}
              label={processing ? "Sending..." : "Submit Deletion Request"}
              variant={"contain"}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
                paddingLeft: 80,
                paddingRight: 80,
                paddingBottom: 10,
                paddingTop: 10,
                width: isLessThanMD ? "100%" : "",
              }}
            />
          }
        />
      </Grid>
    </Box>
  );
}
