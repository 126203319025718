import React, { useContext } from "react";
import Input from "../General/input";
import { HelperContext } from "../../Context/Helper";

export default function Email() {
  const { email, setEmail } = useContext(HelperContext);

  return (
    <Input
      label={"Email Address"}
      type={"email"}
      value={email}
      setter={setEmail}
      name={"email"}
    />
  );
}
