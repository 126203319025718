import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import { HelperContext } from "../../Context/Helper";

export default function Title() {
  const { isLessThanMD } = useContext(HelperContext);

  return (
    <Box
      component={"section"}
      sx={{
        backgroundColor: "white",
        mt: isLessThanMD ? 8 :5,
        width: isLessThanMD ? "" : "35%",
        m: "auto",
        textAlign: "center",
        borderRadius: 50,
        py: 2,
        fontSize: isLessThanMD ? 20 : 24,
      }}
    >
      Account and Data Deletion Request
    </Box>
  );
}
