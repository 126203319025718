import React from "react";
import Hero from "../Components/AccountDeletion/Hero";
import FormComponent from "../Components/AccountDeletion/Form";
import Steps from "../Components/AccountDeletion/steps";
import Info from "../Components/AccountDeletion/info";

export default function AccountDeletion() {
  return (
    <div>
      <Hero />
      <FormComponent />
      <Steps />
      <Info />
    </div>
  );
}
