// external imports
import { createTheme, useMediaQuery } from "@material-ui/core";
import React, { createContext, useState } from "react";

// create Helper context to manage Helper here
export const HelperContext = createContext();

export default function HelperContextProvider(props) {
  // set initial state
  const [scrolled, setScrolled] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [data, setData] = useState("");
  const [reason, setReason] = useState("");

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState(prefersDarkMode);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#069350",
      },
      secondary: {
        main: "#344054",
      },
      mode: mode ? "dark" : "light",
    },
    text: {
      main: "#1D1D1D",
    },
    gray: {
      main: "#999999",
    },
  });

  const isGreaterThanXL = useMediaQuery(theme.breakpoints.up("xl"));
  const isLessThanXL = useMediaQuery(theme.breakpoints.down("xl"));
  const isGreaterThanLG = useMediaQuery(theme.breakpoints.up("lg"));
  const isLessThanLG = useMediaQuery(theme.breakpoints.down("lg"));
  const isGreaterThanMD = useMediaQuery(theme.breakpoints.up("md"));
  const isLessThanMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLessThanSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isGreaterThanSM = useMediaQuery(theme.breakpoints.up("sm"));

  const checkScroll = () => {
    window.addEventListener("scroll", function (event) {
      // To listen for event
      event.preventDefault();

      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  };

  return (
    <HelperContext.Provider
      value={{
        scrolled,
        setScrolled,
        checkScroll,
        mode,
        setMode,
        isGreaterThanXL,
        isLessThanXL,
        isGreaterThanLG,
        isLessThanLG,
        isGreaterThanMD,
        isLessThanMD,
        isLessThanSM,
        isGreaterThanSM,
        theme,
        name,
        setName,
        email,
        setEmail,
        id,
        setId,
        data,
        setData,
        reason,
        setReason,
      }}
    >
      {props.children}
    </HelperContext.Provider>
  );
}
