import React, { useContext } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HelperContext } from "../../Context/Helper";
import Text from "./text";

export default function AccordionComponent({ data }) {
  const [expanded, setExpanded] = React.useState(false);
  const { theme } = useContext(HelperContext);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {data.map((item, index) => (
        <Accordion
          expanded={expanded === `panel ${index}`}
          onChange={handleChange(`panel ${index}`)}
          elevation={0}
          square
          style={{
            borderBottom: `1px solid ${theme.gray.main}`,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Text content={item.title} sx={{ fontWeight: 500, fontSize:20 }} />
          </AccordionSummary>

          <AccordionDetails>{item.content}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
