/* eslint-disable @typescript-eslint/ban-types */
// external import
import { useContext, useMemo } from "react";
import { HelperContext } from "../../Context/Helper";
import { Box, TextField } from "@material-ui/core";
import Text from "./text";

export default function TextArea({
  label,
  placeholder,
  setter,
  value,
  register,
  name,
  error,
  helperText,
  isOptionalText,
  rowMultiplier,
  isDisabled,
}) {
  const { theme } = useContext(HelperContext);
  const optional = useMemo(
    () => (isOptionalText ? "Optional (If applicable)" : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box component={"section"} sx={{ textAlign: "left" }}>
      <Text
        content={label}
        sx={{
          color: theme.palette.secondary.main,
          fontWeight: 500,
          fontSize: 14,
        }}
      />

      <TextField
        disabled={isDisabled}
        fullWidth
        multiline
        rows={rowMultiplier}
        name={name}
        error={error}
        variant="outlined"
        {...(register ? { ...register(name) } : "")}
        helperText={helperText ? helperText : optional}
        value={value}
        placeholder={placeholder}
        onChange={(e) => setter(e.target.value)}
      />
    </Box>
  );
}
