import React, { useContext } from "react";
import Text from "../../../General/text";
import { HelperContext } from "../../../../Context/Helper";

export default function Heading({sx}) {
  const { isLessThanMD } = useContext(HelperContext);

  return (
    <Text
      content={
        "You can request to delete your account and any associated data with OOMA Meal Plan following the steps below"
      }
      sx={{ fontSize: 20, fontWeight: 500, mx:isLessThanMD ? 0 : 2, ...sx }}
    />
  );
}
