import React from "react";

export default function Step3() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="#069350" stroke="black" />
      <path
        d="M15.7557 25.2386C14.5852 25.2386 13.5398 25.0369 12.6193 24.6335C11.7045 24.2301 10.9801 23.6705 10.446 22.9545C9.91761 22.233 9.63352 21.3977 9.59375 20.4489H12.2699C12.304 20.9659 12.4773 21.4148 12.7898 21.7955C13.108 22.1705 13.5227 22.4602 14.0341 22.6648C14.5455 22.8693 15.1136 22.9716 15.7386 22.9716C16.4261 22.9716 17.0341 22.8523 17.5625 22.6136C18.0966 22.375 18.5142 22.0426 18.8153 21.6165C19.1165 21.1847 19.267 20.6875 19.267 20.125C19.267 19.5398 19.1165 19.0256 18.8153 18.5824C18.5199 18.1335 18.0852 17.7812 17.5114 17.5256C16.9432 17.2699 16.2557 17.142 15.4489 17.142H13.9744V14.9943H15.4489C16.0966 14.9943 16.6648 14.8778 17.1534 14.6449C17.6477 14.4119 18.0341 14.0881 18.3125 13.6733C18.5909 13.2528 18.7301 12.7614 18.7301 12.1989C18.7301 11.6591 18.608 11.1903 18.3636 10.7926C18.125 10.3892 17.7841 10.0739 17.3409 9.84659C16.9034 9.61932 16.3864 9.50568 15.7898 9.50568C15.2216 9.50568 14.6903 9.6108 14.196 9.82102C13.7074 10.0256 13.3097 10.321 13.0028 10.7074C12.696 11.0881 12.5313 11.5455 12.5085 12.0795H9.96023C9.98864 11.1364 10.267 10.3068 10.7955 9.59091C11.3295 8.875 12.0341 8.31534 12.9091 7.91193C13.7841 7.50852 14.7557 7.30682 15.8239 7.30682C16.9432 7.30682 17.9091 7.52557 18.7216 7.96307C19.5398 8.39489 20.1705 8.97159 20.6136 9.69318C21.0625 10.4148 21.2841 11.2045 21.2784 12.0625C21.2841 13.0398 21.0114 13.8693 20.4602 14.5511C19.9148 15.233 19.1875 15.6903 18.2784 15.9233V16.0597C19.4375 16.2358 20.3352 16.696 20.9716 17.4403C21.6136 18.1847 21.9318 19.108 21.9261 20.2102C21.9318 21.1705 21.6648 22.0312 21.125 22.7926C20.5909 23.554 19.8608 24.1534 18.9347 24.5909C18.0085 25.0227 16.9489 25.2386 15.7557 25.2386Z"
        fill="white"
      />
    </svg>
  );
}
