import { Box } from "@material-ui/core";
import React from "react";

export default function Img() {
  return (
    <Box component={"aside"}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11.5" fill="#CC9494" stroke="black" />
        <path
          d="M12.0044 9.33412V12.0008M12.0044 14.6675H12.0111M10.8644 5.90745L5.21774 15.3341C5.10132 15.5357 5.03972 15.7643 5.03907 15.9971C5.03842 16.2299 5.09874 16.4589 5.21403 16.6611C5.32932 16.8634 5.49556 17.032 5.69621 17.15C5.89687 17.2681 6.12494 17.3316 6.35774 17.3341H17.6511C17.8839 17.3316 18.1119 17.2681 18.3126 17.15C18.5133 17.032 18.6795 16.8634 18.7948 16.6611C18.9101 16.4589 18.9704 16.2299 18.9697 15.9971C18.9691 15.7643 18.9075 15.5357 18.7911 15.3341L13.1444 5.90745C13.0256 5.71153 12.8582 5.54953 12.6585 5.43711C12.4589 5.32469 12.2336 5.26562 12.0044 5.26562C11.7753 5.26562 11.55 5.32469 11.3503 5.43711C11.1506 5.54953 10.9833 5.71153 10.8644 5.90745Z"
          stroke="#101828"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
}
