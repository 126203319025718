import React, { useContext } from "react";
import { HelperContext } from "../../Context/Helper";
import TextArea from "../General/textarea";

export default function Reason() {
  const { reason, setReason } = useContext(HelperContext);

  return (
    <TextArea
      label={"Reason(s) for Deletion Request"}
      value={reason}
      setter={setReason}
      name={"reason"}
      isOptionalText={true}
      rowMultiplier={5}
    />
  );
}
