import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import AccordionComponent from "../../General/accordion";
import Types from "./types";
import Data from "./data";
import { HelperContext } from "../../../Context/Helper";
import TypesMobile from "./mobile/types";
import DataMobile from "./mobile/data";

export default function Info() {
  const { isLessThanMD } = useContext(HelperContext);

  const data = [
    {
      title: "Types of data that will be deleted",
      content: isLessThanMD ? <TypesMobile /> : <Types />,
    },
    {
      title: "Data retention policy",
      content: isLessThanMD ? <DataMobile /> : <Data />,
    },
  ];

  return (
    <Box
      component={"section"}
      sx={{
        width: isLessThanMD ? "95%" : "90%",
        m: "auto",
        mt: 5,
        mb: 15,
      }}
    >
      <AccordionComponent data={data} />
    </Box>
  );
}
