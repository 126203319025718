import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import Header from "./header";
import { HelperContext } from "../../../Context/Helper";
import Body from "./Body";

export default function Steps() {
  const { theme, isLessThanMD } = useContext(HelperContext);

  return (
    <Box
      component={"section"}
      sx={{
        width: isLessThanMD ? "95%" : "90%",
        borderRadius: 20,
        border: `1px solid ${theme.palette.primary.light}`,
        m: "auto",
        my: isLessThanMD ? 2.5 :10,
      }}
    >
      <Box
        component={"section"}
        sx={{ mx: isLessThanMD ? 2.5 : 10, my: isLessThanMD ? 2.5 : 5 }}
      >
        <Header />
        <Body />
      </Box>
    </Box>
  );
}
