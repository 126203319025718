import React, { useContext } from "react";
import NavV2 from "../HomeV2/HeroAndNav/NavV2";
import { Box } from "@material-ui/core";
import { HelperContext } from "../../Context/Helper";
import Title from "./title";

export default function Hero() {
  const { theme, isLessThanMD } = useContext(HelperContext);
  
  return (
    <Box
      sx={{
        height:isLessThanMD ? 200 : 300,
        backgroundColor: theme.palette.primary.main,
        p: 2,
        mb: 7.5,
      }}
    >
      <NavV2 />
      <Title />
    </Box>
  );
}
