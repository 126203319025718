/* eslint-disable @typescript-eslint/ban-types */
// external import
import { Box, InputAdornment, TextField } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import { HelperContext } from "../../Context/Helper";
import Text from "./text";

export default function Input({
  label,
  value,
  placeholder,
  type,
  name,
  setter,
  error,
  helperText,
  isOptionalText,
  register,
  startAdornment,
  endAdornment,
  isDisabled,
  variant,
  sx,
}) {
  const { theme } = useContext(HelperContext);
  const optional = useMemo(
    () => (isOptionalText ? "Optional (If applicable)" : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box component={"aside"}>
      <Text
        content={label}
        sx={{
          color: theme.palette.secondary.main,
          fontWeight: 500,
          fontSize: 14,
        }}
      />

      <TextField
        style={{ ...sx }}
        size="small"
        disabled={isDisabled}
        id={[...name].join("")}
        fullWidth
        name={name}
        error={error}
        variant={variant ? "standard" : "outlined"}
        {...(register ? { ...register(name) } : "")}
        helperText={helperText ? helperText : optional}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={(e) => setter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ cursor: "pointer" }}>
              {startAdornment}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {endAdornment}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
