import React from "react";

export default function Step2() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="#069350" stroke="black" />
      <path
        d="M9.67898 25V23.0909L15.5852 16.9716C16.2159 16.3068 16.7358 15.7244 17.1449 15.2244C17.5597 14.7187 17.8693 14.2386 18.0739 13.7841C18.2784 13.3295 18.3807 12.8466 18.3807 12.3352C18.3807 11.7557 18.2443 11.2557 17.9716 10.8352C17.6989 10.4091 17.3267 10.0824 16.8551 9.85511C16.3835 9.62216 15.8523 9.50568 15.2614 9.50568C14.6364 9.50568 14.0909 9.63352 13.625 9.8892C13.1591 10.1449 12.8011 10.5057 12.5511 10.9716C12.3011 11.4375 12.1761 11.983 12.1761 12.608H9.66193C9.66193 11.5455 9.90625 10.6165 10.3949 9.82102C10.8835 9.02557 11.554 8.40909 12.4062 7.97159C13.2585 7.52841 14.2273 7.30682 15.3125 7.30682C16.4091 7.30682 17.375 7.52557 18.2102 7.96307C19.0511 8.39489 19.7074 8.98579 20.179 9.73579C20.6506 10.4801 20.8864 11.321 20.8864 12.2585C20.8864 12.9062 20.7642 13.5398 20.5199 14.1591C20.2813 14.7784 19.8636 15.4687 19.267 16.2301C18.6705 16.9858 17.8409 17.9034 16.7784 18.983L13.3097 22.6136V22.7415H21.1676V25H9.67898Z"
        fill="white"
      />
    </svg>
  );
}
