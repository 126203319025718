import React from "react";

export default function Step1() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="#069350" stroke="black" />
      <path
        d="M18.3011 7.54545V25H15.6591V10.1875H15.5568L11.3807 12.9148V10.392L15.7358 7.54545H18.3011Z"
        fill="white"
      />
    </svg>
  );
}
