import React from "react";
import Content from "./Content";

export default function Resources() {
  const data = [
    {
      title: "Home",
      link: "/",
      icon: "",
    },
  ];
  return <Content heading={"Resources"} data={data} />;
}
