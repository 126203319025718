import React from "react";
import AnchorTag from "../../Helpers/AnchorTag";
import Text from "../../General/text";
import { Box } from "@material-ui/core";
import Quarter from "../../grid/Quarter";
import Form from "./Form";

export default function Content({ heading, data, isForm }) {
  return (
    <Quarter
      content={
        <Box component={"section"}>
          <Text content={heading} sx={{ fontSize: 24, fontWeight: 600 }} />

          {isForm ? (
            <Form />
          ) : (
            <Box component={"aside"} sx={{ my: 2.5 }}>
              {data &&
                data.map((item) => (
                  <AnchorTag
                    link={item.link}
                    icon={item.icon}
                    title={item.title}
                  />
                ))}
            </Box>
          )}
        </Box>
      }
    />
  );
}
