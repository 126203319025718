import { Button } from "@material-ui/core";

export default function Btn({
  variant,
  href,
  label,
  sx,
  icon,
  handleClick,
  iconL,
}) {
  return (
    <Button
      variant={variant}
      href={href}
      style={{ ...sx, textTransform: "capitalize" }}
      onClick={handleClick}
    >
      {iconL}
      {label}
      {icon}
    </Button>
  );
}
