import React from "react";
import Content from "./Content";
import { ImWhatsapp, ImEnvelop } from "react-icons/im";
import { BiPhoneCall } from "react-icons/bi";

export default function Contact() {
  const data = [
    {
      title: "08067723579",
      link: "https://wa.me/2348067723579",
      icon: <ImWhatsapp />,
    },

    {
      title: "08067723579",
      link: "tel:2348101234567",
      icon: <BiPhoneCall />,
    },

    {
      title: "support@ooma.kitchen",
      link: "mailto:support@ooma.kitchen",
      icon: <ImEnvelop />,
    },
  ];

  return <Content heading={"Contact us"} data={data} />;
}
