import { Box, Grid } from "@material-ui/core";
import React from "react";
import Step1 from "./img/1";
import Step2 from "./img/2";
import Step3 from "./img/3";
import Step from "./step";

export default function Body() {
  const data = [
    {
      img: <Step1 />,
      num: 1,
      text: <>Submit the form with the required details</>,
    },

    {
      img: <Step2 />,
      num: 2,
      text: (
        <>
          Our team will verify your request and <br /> associated account
          details
        </>
      ),
    },

    {
      img: <Step3 />,
      num: 3,
      text: (
        <>
          Upon verification, we will delete your <br /> account and associated
          data within 30 days
        </>
      ),
    },
  ];
  return (
    <Box component={"section"} sx={{ py: 5 }}>
      <Grid container spacing={1}>
        {data &&
          data.map((item) => (
            <Step img={item.img} num={item.num} text={item.text} />
          ))}
      </Grid>
    </Box>
  );
}
