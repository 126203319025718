import React, { useContext } from "react";
import Input from "../General/input";
import { HelperContext } from "../../Context/Helper";

export default function Data() {
  const { data, setData } = useContext(HelperContext);

  return (
    <Input
      label={"Please, specify what data you want deleted"}
      type={"text"}
      value={data}
      setter={setData}
      name={"data"}
      isOptionalText={true}
    />
  );
}
