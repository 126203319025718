import { Box } from "@material-ui/core";

export default function Text({ content, sx }) {
  return (
    <Box
      component={"p"}
      sx={{ fontSize: 16, fontWeight: 400, m: 0, ...sx, lineHeight: 1.5 }}
    >
      {content}
    </Box>
  );
}
