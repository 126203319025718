import { Grid } from "@material-ui/core";
import React from "react";

export default function OneThird({ content, sx }) {
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} style={{...sx}}>
      {content}
    </Grid>
  );
}
