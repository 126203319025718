import React, { useContext } from "react";
import Input from "../General/input";
import { HelperContext } from "../../Context/Helper";

export default function Name() {
  const { name, setName } = useContext(HelperContext);

  return (
    <Input
      label={"Full Name"}
      type={"name"}
      value={name}
      setter={setName}
      name={"name"}
    />
  );
}
