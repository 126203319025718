import { Grid } from "@material-ui/core";
import React from "react";

export default function Quarter({ content }) {
  return (
    <Grid item xs={12} sm={12} md={3} lg={3}>
      {content}
    </Grid>
  );
}
