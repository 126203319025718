import React from "react";
import Content from "./Content";

export default function TermsOfUse() {
  const data = [
    {
      title: "Terms of service",
      link: "/terms-of-use",
      icon: "",
    },
    {
      title: "Privacy policy",
      link: "/privacy-policy",
      icon: "",
    },
    {
      title: "Account and Data Deletion Request",
      link: "/account-deletion",
      icon: "",
    },
  ];
  return <Content heading={"Agreements"} data={data} />;
}
