import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import Img from "./img";
import Heading from "./heading";
import { HelperContext } from "../../../../Context/Helper";

export default function Header() {
  const { isLessThanMD } = useContext(HelperContext);

  return (
    <Box
      component={"header"}
      style={{
        display: isLessThanMD ? "" : "flex",
        justifyContent: 'center',
        alignItems:"center"
      }}
    >
      <Img />
      <Heading />
    </Box>
  );
}
