import React, { useContext } from "react";
import OneThird from "../../../grid/OneThird";
import Text from "../../../General/text";
import { HelperContext } from "../../../../Context/Helper";

export default function Step({ img, num, text }) {
  const { isLessThanMD } = useContext(HelperContext);

  return (
    <OneThird
      sx={{ textAlign: "center" }}
      content={
        <>
          {img}
          <Text
            content={`Step ${num}`}
            sx={{ mt: 2, mb: 1, fontSize: 14, fontWeight: 500 }}
          />
          <Text
            content={text}
            sx={{ fontSize: 14, pb: isLessThanMD ? 5 : "" }}
          />
        </>
      }
    />
  );
}
