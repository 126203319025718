import { Box } from "@material-ui/core";
import React from "react";
import Text from "../../General/text";

export default function Header() {
  return (
    <Box component={"header"}>
      <Text
        sx={{ fontSize: 24, fontWeight: 500 }}
        content="Request Form Section"
      />
      <Text
        content="When you request to delete your account and associated data, your
        information will be permanently removed from our systems."
        sx={{ mt: 1}}
      />
    </Box>
  );
}
