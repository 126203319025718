import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import Header from "./header";
import Form from "./form";
import { HelperContext } from "../../../Context/Helper";

export default function FormComponent() {
  const { isLessThanMD } = useContext(HelperContext);

  return (
    <Box
      component={"div"}
      sx={{
        width: isLessThanMD ? "95%" : "90%",
        m: "auto",
        borderRadius: 20,
        border: "2px solid black",
        mt: isLessThanMD ? "" : -20,
        backgroundColor: "white",
      }}
    >
      <Box
        component={"section"}
        sx={{ mx: isLessThanMD ? 2.5 : 10, mt: isLessThanMD ? 2.5 : 10 }}
      >
        <Header />
        <Form />
      </Box>
    </Box>
  );
}
