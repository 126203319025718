import React, { useContext } from "react";
import Input from "../General/input";
import { HelperContext } from "../../Context/Helper";

export default function Id() {
  const { id, setId } = useContext(HelperContext);

  return (
    <Input
      label={"Associated Account ID/User ID"}
      type={"text"}
      value={id}
      setter={setId}
      name={"id"}
      isOptionalText={true}
    />
  );
}
