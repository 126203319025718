import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import TelegramIcon from "@material-ui/icons/Telegram";
import Input from "../../../Components/General/input";
import Btn from "../../../Components/General/btn";
import { HelperContext } from "../../../Context/Helper";

export default function Form() {
  const { theme } = useContext(HelperContext);

  return (
    <Box component={"aside"} sx={{ mt: 2.5 }}>
      <Input
        type={"text"}
        name={"Newsletter"}
        placeholder={"Enter email address"}
        sx={{
          backgroundColor: "white",
          borderRadius: 5,
        }}
        endAdornment={
          <Btn
            label={
              <TelegramIcon
                style={{
                  color: "white",
                  fontSize: "32",
                  padding: "0",
                  backgroundColor: theme.palette.primary.main,
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius: 5,
                  marginRight: -40,
                }}
              />
            }
          />
        }
      />
    </Box>
  );
}
