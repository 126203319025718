import { Box, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import Contact from "./Footer/Contact";
import Newsletter from "./Footer/Newsletter";
import Resources from "./Footer/Resources";
import TermsOfUse from "./Footer/TermsOfUse";
import { HelperContext } from "../../Context/Helper";
import Text from "../General/text";
import Full from "../grid/full";

export default function Footer() {
  const { theme } = useContext(HelperContext);

  return (
    <Box
      component={"footer"}
      sx={{
        backgroundColor: theme.palette.primary.main,
        py: 5,
        color: "white",
      }}
    >
      <Grid container style={{ width: "90%", margin: "auto" }}>
        <Resources />
        <TermsOfUse />
        <Contact />
        <Newsletter />

        <Full
          sx={{ textAlign: "center", marginTop: 50 }}
          content={
            <Text
              sx={{ fontSize: 20, fontWeight: 600 }}
              content={<>&copy; {new Date().getFullYear()} OOMA TECHNOLOGIES</>}
            />
          }
        />
      </Grid>
    </Box>
  );
}
