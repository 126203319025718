import { Box } from "@material-ui/core";
import React from "react";

export default function AnchorTag({ icon, title, link }) {
  return (
    <Box
      component={"a"}
      href={link}
      style={{
        fontSize: 16,
        color: "white",
        textDecoration: "none",
        display: "block",
        marginBottom: 15,
      }}
    >
      <Box component={"span"}>{icon}</Box>
      <Box component={"span"} sx={{ mx: 1 }}>
        {title}
      </Box>
    </Box>
  );
}
